import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class FormulaService {
    readFormulaQuery(){
        var query = `
            query ($paging:ServerPaging, $status:String) {
                GetFormula (Paging:$paging, Status:$status) {
                    Formula {
                        created_at
                        last_update
                        formula_id
                        formula_name
                        formula_code
                        item_id
                        buffer_quantity_pct
                        status
                        product_code
                        base_uom
                        created_by
                        item_name
                        is_active
                    }
                    Total
                }
            }
        `;
        return query;
    }
    
    async getFormulaDetail(id){
        const variables = {
            id : id
        }
        var query = gql`query ($id:Int) {
                GetFormula (FormulaId:$id) {
                    Formula {
                        created_at
                        last_update
                        formula_id
                        formula_name
                        formula_code
                        item_id
                        buffer_quantity_pct
                        status
                        product_code
                        base_uom
                        created_by
                        item_name
                        is_active
                        formula_detail {
                            created_at
                            last_update
                            formula_detail_id
                            formula_id
                            type_detail_id
                            type_detail_name
                            weight
                        }
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetFormula.Formula[0];
    }

    async getItemQuery(){
        var query = gql`query{
            GetProductMaster{
                ProductMaster{
                    item_id
                    product_code
                    item_name
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetProductMaster.ProductMaster;
        var itemData = [];
        for (let i = 0; i < data.length; i++) {
            var str = { value: data[i].item_id, 
                label: data[i].product_code + ' (' + data[i].item_name + ')'}
            itemData.push(str);
        }

        return itemData;
    }

    convertTypeDetailSave(data, id){
        var result = [];

        for (let i = 0; i < data.length; i++) {
            var str = { formula_detail_id: data[i].formula_detail_id, 
                        formula_id: id,
                        type_detail_id: data[i].type_detail_id,
                        weight: data[i].weight}
            result.push(str);
        }

        return result;
    }

    getTypeDetailDataQuery(){
        var query = `
            query {
                GetTypeDetail(Flags:["All","Project","Retail"]){
                    type_detail_id
                    type_detail_name
                }
            }
        `;
        return query;
    }

    gridSelector(selected, checkall, data){
        if (checkall == 'true'){
            if(selected == ''){
                var newData = data;
                return newData;
            }else{
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.type_detail_id]= el
                })
                for (let i = 0; i < selectedData.length; i++) {
                    delete hashMap[selectedData[i].toString()]
                }
                var newData = [];
                Object.keys(hashMap).forEach(el=>{
                    newData.push(hashMap[el])
                })
                return newData;
            }
        }else{
            if(selected == ''){
                var newData = [];
                return newData;
            }else{
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.type_detail_id]= el
                })
                var newData = [];
                for (let i = 0; i < selectedData.length; i++) {
                    newData.push(hashMap[selectedData[i].toString()])
                }
                return newData;
            }
        }
    }

    async addQuery(variables){
        var query = gql`
            mutation ( $data:NewMasterFormula! ) {
                CreateFormula ( New:$data )
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        var query = gql`
            mutation ( $id:Int!, $data:NewMasterFormula! ) {
                UpdateFormula ( FormulaID:$id, New:$data )
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        const query = gql`mutation($id:Int!){
            DeleteFormula (FormulaID:$id)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
}

export default new FormulaService();