<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Formula</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <formula-grid ref="grid" :key="gridReload" :deleteClick="deleteClick" :editClick="editClick" :statusClick="statusClick"/>
                <formula-status-form ref="FormulaStatusForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import formulaService from '../Formula/Script/FormulaServices';
import formulaGrid from '../Formula/Grid/FormulaGrid.vue';
import formulaStatusForm from '../Formula/Component/FormulaStatusForm.vue';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'Formula',
    components: {
        'formula-grid': formulaGrid,       
        'formula-status-form': formulaStatusForm
    },
    mounted () {
        var permission = this.$globalfunc.permissionModule('Formula');
        document.getElementById("add").disabled = permission.cperms ? false : true;

        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$router.push({ name: 'Formula Form', params: { formtype:'Add', id:' ', view:'new' } });
        },
        editClick(id, view){
            this.$router.push({ name: 'Formula Form', params: { formtype:'Edit', id:id, view:view } });
        },
        deleteClick(id){
            this.$swal(sweet_alert.delete).then((result) => {
                var variables = {
                    id : id
                }
                if (result.isConfirmed == true) {
                    formulaService.deleteQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.errorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        statusClick(id) {
            this.$refs.FormulaStatusForm.statusClick(id);
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnApproved").on('click', function() {
                    vue.$refs.grid.changeStatus('Approved');
                });
                $("#btnDelete").on('click', function() {
                    vue.$refs.grid.changeStatus('Delete');
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
            })
        },
    }
}
</script>

<style scoped>
</style>